<script>
import Layout from "../../../layouts/main.vue";
// import PageHeader from "@/components/page-header";
import useGlobalData from "@/state/global-data";
import axios from "axios";

export default {
  page: {
    title: "Configuration",
  },
  methods: {
    applyCustomers() {
      axios.post(process.env.VUE_APP_API_BASE_URL+`/management/v1.0.0/config/update_usergroups`, {})
          .then(() => {
            // TODO confirmation
          }).catch((er) => {
        console.log(er)
      });
    },
    enableService(service, enabled) {
      const data = {
        service: service,
        enabled: enabled,
      }
      axios.post(process.env.VUE_APP_API_BASE_URL+`/management/v1.0.0/config/service`, data)
          .then(() => {
            this.loadConfig();
          }).catch((er) => {
        console.log(er)
      });
    },
    handleCDRChange($event) {
      this.enableService('clicksafe_attachments', $event.target.checked);
    },
    handlePhishingChange($event) {
      this.enableService('clicksafe_links', $event.target.checked);
    },
    handleSpearPhishingChange($event) {
      this.enableService('scamalert', $event.target.checked);
    },
    handleGroupChange() {
      this.selectedType = null;
      this.loadConfig();
    },
    handleSearchInput(event) {
      this.searchQuery = event.target.value;
    },
    loadPreset: function() {
      const data = {
        preset: document.getElementById('preset-select').value,
      }
      axios.post(process.env.VUE_APP_API_BASE_URL+`/management/v1.0.0/config/load_preset`, data)
          .then(() => {
            this.loadConfig();
            this.presetEnabled = false;
          }).catch((er) => {
        console.log(er)
      });
    },
    updateAction: function(extension, action) {
      const groupId = document.getElementById('group-select').value;
      const data = {
        extension: extension,
        action: action,
        endusergroup_id: groupId !== '0' ? groupId : null,
      }
      axios.post(process.env.VUE_APP_API_BASE_URL+`/management/v1.0.0/config/update_action`, data)
          .then(() => {

          }).catch((er) => {
        console.log(er)
      });
    },
    updateOption: function(option, option_value) {
      const data = {
        option: option,
        value: option_value,
      }
      axios.post(process.env.VUE_APP_API_BASE_URL+`/management/v1.0.0/config/update_option`, data)
          .then(() => {

          }).catch((er) => {
        console.log(er)
      });
    },
    setPages() {
      let numberOfPages = Math.ceil(this.actions.length / this.perPage);
      this.pages = [];
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
    },
    paginate(items) {
      let page = this.page;
      let perPage = this.perPage;
      let from = page * perPage - perPage;
      let to = page * perPage;
      this.paginatedItems = items;
      return items.slice(from, to);
    },
    setPagesGroups() {
      let numberOfPages = Math.ceil(this.groups.length / this.perPageGroups);
      this.pagesGroups = [];
      for (let index = 1; index <= numberOfPages; index++) {
        this.pagesGroups.push(index);
      }
    },
    paginateGroups(items) {
      let page = this.pageGroups;
      let perPage = this.perPageGroups;
      let from = page * perPage - perPage;
      let to = page * perPage;
      return items.slice(from, to);
    },
    loadConfig() {
      const data = {}
      axios.post(process.env.VUE_APP_API_BASE_URL+'/management/v1.0.0/config/list', data).then((data) => {
        this.options = data.data.options;
        this.defaults = data.data.defaults;
        this.serviceCDREnabled = data.data.service_cdr_enabled;
        this.servicePhishingEnabled = data.data.service_phishing_enabled;
        this.serviceSpearPhishingEnabled = data.data.service_spearphishing_enabled;
        this.actions = [];
        data.data.actions.forEach((row) => {
          this.actions.push(row)
        })
        this.actions.sort((a, b) => b.name - a.name)
      }).catch((er) => {
        console.log(er)
      });
    },
    selectTab(event, selectedTabId) {
      // Select all elements with the class 'tab-panel'
      const tabPanels = document.querySelectorAll('.tab-pane-config');
      // Loop through the NodeList of elements
      tabPanels.forEach(panel => {
        // Remove the 'active' class from each element
        panel.classList.remove('active');
      });
      const tabPanel = document.getElementById(selectedTabId);
      tabPanel.classList.add('active');

      // Select all elements with the class 'nav-link'
      const navLinks = document.querySelectorAll('.nav-link-config');
      navLinks.forEach(navLink => {
        // Remove the 'active' class from each element
        navLink.classList.remove('active');
      });
      event.target.classList.add('active');
    },
  },
  data() {
    const {userType, usergroupType, provisioningKey} = useGlobalData();
    return {
      title: this.$t("t-menu-configuration"),
      searchQuery: null,
      selectedType: null,
      emptyGroups: false,
      items: [],
      page: 1,
      pages: [],
      perPage: 8,
      pageGroups: 1,
      pagesGroups: [],
      perPageGroups: 8,
      actions: [],
      groups: [],
      options: {},
      provisioningKey,
      usergroupType,
      userType,
      internal_domains: [],
      defaults: {},
      presetEnabled: false,
      serviceCDREnabled: false,
      servicePhishingEnabled: false,
      serviceSpearPhishingEnabled: false,
      showAdvancedActions: false,
      clickSafeLinksOption: null,
      clickSafeLinksOptionNewSender: null,
      clickSafeLinksOptionTrustedSender: null,
    };
  },
  beforeMount() {
  },
  mounted() {
    const checkAuthorization = setInterval(() => {
      if ('authorization' in axios.defaults.headers.common) {
        this.loadConfig();
        clearInterval(checkAuthorization);
      }
    }, 200);
    let tabValue = this.$route.query.tab;
    if (tabValue === "ad") {
      this.$refs.tab_ad.click();
    } else if (tabValue === "malware") {
      this.$refs.tab_malware.click();
    } else if (tabValue === "phishing") {
      this.$refs.tab_phishing.click();
    } else if (tabValue === "spearphishing") {
      this.$refs.tab_spearphishing.click();
    }
  },
  computed: {
    displayedActions() {
      return this.paginate(this.actions);
    },
    resultQueryActions() {
      if (this.searchQuery) {
        const search = this.searchQuery.toLowerCase();
        return this.actions.filter((data) => {
          return (
              data.name.toLowerCase().includes(search)
          );
        });
      } else {
        return this.displayedActions;
      }
    },
    displayedGroups() {
      return this.paginateGroups(this.groups);
    },
  },
  watch: {
    actions() {
      this.setPages();
    },
    groups() {
      this.setPagesGroups();
    },
  },
  created() {
    this.setPages();
    this.setPagesGroups();
  },
  components: {
    Layout,
    // PageHeader,
  },
};
</script>


<template>
  <Layout>
    <!--    <PageHeader :title="title" :items="items" />-->
    <div class="row" data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-delay="100" data-aos-offset="0">
      <div class="col-12 d-flex">
        <h4 class="flex-shrink-0">{{ $t("t-menu-configuration") }}</h4>
        <div class="flex-shrink-0">
          <button type="button" class="btn btn-success add-btn ms-3 btn-sm" @click="applyCustomers()">
            <i class="ri-file-copy-2-line align-bottom me-1"></i> {{ $t("t-config-update-clients") }}
          </button>
        </div>
      </div>
      <div class="col-12 mt-2">
        <p v-html='$t("t-config-description")'></p>
      </div>
      <div class="col-12 d-flex mt-3">
        <br>
        <!-- Nav tabs -->
        <ul class="nav nav-tabs nav-border-top nav-border-top-primary mb-3" role="tablist">
          <li class="nav-item">
            <a class="nav-link nav-link-config active" data-bs-toggle="tab" href="#nav-spearphishing" role="tab"
               aria-selected="false" ref="tab_spearphishing" @click="selectTab($event, 'nav-spearphishing')">
              {{ $t("t-config-menu-spearphishing") }}
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link nav-link-config" data-bs-toggle="tab" data-bs-target="#nav-phishing" href="#nav-phishing" role="tab"
               aria-selected="false" ref="tab_phishing" @click="selectTab($event, 'nav-phishing')">
              {{ $t("t-config-menu-phishing") }}
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link nav-link-config" data-bs-toggle="tab" data-bs-target="#nav-files"  href="#nav-files" role="tab"
               aria-selected="false" ref="tab_actions" @click="selectTab($event, 'nav-files')">
              {{ $t("t-config-menu-actions") }}
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link nav-link-config" data-bs-toggle="tab" href="#nav-other" role="tab"
               aria-selected="false" ref="tab_options" @click="selectTab($event, 'nav-other')">
              {{ $t("t-config-menu-options") }}
            </a>
          </li>
        </ul>
      </div>
    </div>
    <!-- Tab panes -->
    <div class="tab-content" data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-delay="100" data-aos-offset="0">
      <div class="tab-pane tab-pane-config" id="nav-files" role="tabpanel">
        <div class="row">
          <div class="col-xxl-6 col-lg-6">
            <div class="card">
              <div class="card-header align-items-center d-flex">
                <h4 class="card-title mb-0">
                  <!--                  <span class="badge bg-info text-dark text-decoration-none" style="background-color: rgba(58, 76, 98, 0.1) !important;">ClickSafe</span>-->
                  {{ $t("t-config-menu-actions") }}
                </h4>
                <div class="form-check form-switch form-switch-md mt-0 mb-0 pt-0 pb-0 ms-3">
                  <input class="form-check-input" type="checkbox" role="switch" @change="handleCDRChange" :checked="serviceCDREnabled">
                  <label>{{ $t("t-config-action-enable") }}</label>
                </div>
              </div>
              <!-- end card-header -->
              <div class="card-body">
                <p v-html="$t('t-config-action-explainer')"></p>
                <br>
                <div style="width: 500px;">
                    <span class="mb-1 d-block fw-semibold">
                      {{ $t("t-config-action-banner-reconstructed") }}
                    </span>
                  <div class="input-group">
                    <input type="text" ref="clicksafe_attachment_banner_reconstructed" autocomplete="off" class="form-control" :value="options && 'm365_clicksafe_attachment_banner_reconstructed' in options ? options['m365_clicksafe_attachment_banner_reconstructed'] : ''" :placeholder="'m365_clicksafe_attachment_banner_reconstructed' in defaults ? defaults['m365_clicksafe_attachment_banner_reconstructed'] : ''">
                    <span class="input-group-text cursor-pointer" @click="updateOption('m365_clicksafe_attachment_banner_reconstructed', $refs.clicksafe_attachment_banner_reconstructed.value)">{{ $t("t-config-options-m365-banner-save") }}</span>
                  </div>
                </div>

                <br>
                <div style="width: 500px;">
                    <span class="mb-1 d-block fw-semibold">
                      {{ $t("t-config-action-banner-blocked") }}
                    </span>
                  <div class="input-group">
                    <input type="text" ref="clicksafe_attachment_banner_blocked" autocomplete="off" class="form-control" :value="options && 'm365_clicksafe_attachment_banner_blocked' in options ? options['m365_clicksafe_attachment_banner_blocked'] : ''" :placeholder="'m365_clicksafe_attachment_banner_blocked' in defaults ? defaults['m365_clicksafe_attachment_banner_blocked'] : ''">
                    <span class="input-group-text cursor-pointer" @click="updateOption('m365_clicksafe_attachment_banner_blocked', $refs.clicksafe_attachment_banner_blocked.value)">{{ $t("t-config-options-m365-banner-save") }}</span>
                  </div>
                </div>

                <br>

                <span class="cursor-pointer fst-italic" @click="showAdvancedActions=!showAdvancedActions">{{ $t("t-config-action-advanced-settings") }}</span>
                <br>

              </div>

            </div>

          </div>
        </div>


        <div v-show="showAdvancedActions" class="row">
          <div class="col-xxl-6 col-lg-6">

            <div class="card">
              <div class="card-header">
                <h4 class="card-title mb-0 flex-grow-1">{{ $t("t-config-action-formats") }}</h4>
              </div>
              <!-- end card-header -->

              <div class="card-header align-items-center d-flex">
                <div class="card-title mb-0 flex-grow-1 d-flex justify-content-between">
                  <div style="max-width:300px; display: none;">
                    <div class="input-group">
                      <label class="input-group-text" for="group-select">{{ $t("t-endusers-group") }}</label>
                      <select class="form-select" id="group-select" @change="handleGroupChange">
                        <option selected value="0">{{ $t("t-endpoints-group-default") }}</option>
                      </select>
                    </div>
                  </div>
                  <div style="max-width:300px;">
                    <div class="input-group" v-if="!presetEnabled">
                      <button class="input-group-text" @click="presetEnabled = true">{{ $t("t-config-preset") }}</button>
                    </div>
                    <div class="input-group" v-if="presetEnabled">
                      <select class="form-select" id="preset-select">
                        <option value="default">{{ $t("t-config-preset-default") }}</option>
                        <option value="min">{{ $t("t-config-preset-min") }}</option>
                        <option value="sanitize">{{ $t("t-config-preset-sanitize") }}</option>
                        <option value="max">{{ $t("t-config-preset-max") }}</option>
                      </select>
                      <button class="input-group-text" @click="loadPreset">{{ $t("t-config-preset-load") }}</button>
                      <button class="input-group-text" @click="presetEnabled = false"><i class="ri ri-close-line"></i></button>
                    </div>
                  </div>
                </div>
                <div class="flex-shrink-0">
                  <!--                  <div class="input-group">-->
                  <!--                    <select class="form-select" id="template-select">-->
                  <!--                      <option selected>Select template</option>-->
                  <!--                      <option value="1">Sanitize only</option>-->
                  <!--                      <option value="2">Full protection</option>-->
                  <!--                    </select>-->
                  <!--                    <label class="input-group-text" for="template-select">Apply</label>-->
                  <!--                  </div>-->
                </div>
              </div>
              <div class="card-body">
                <form data-form-type="search">
                  <div class="row g-3">
                    <div class="col-sm-12 col-xxl-12">
                      <div class="search-box"><input @input="handleSearchInput" type="text" class="form-control search bg-light border-light" :placeholder="$t('t-search')" data-form-type="query"><i class="ri-search-line search-icon"></i></div>
                    </div>
                  </div>
                </form>
              </div>
              <!-- end card-header -->
              <div class="card-body">
                <div class="table-responsive table-card">
                  <table class="table align-middle" id="customerTable">
                    <thead class="table-light">
                    <tr>
                      <th scope="col" style="width: 20px"></th>
                      <th class="sort" data-sort="id">{{ $t("t-config-list-type") }}</th>
                      <th class="sort" data-sort="created">{{ $t("t-config-list-default") }}</th>
                      <!--                      <th class="sort" data-sort="deactivated">{{ $t("t-config-list-exceptions") }}</th>-->
                    </tr>
                    </thead>
                    <tbody class="list form-check-all">
                    <tr v-for="(data, index) of resultQueryActions" :key="index" class="cursor-pointer" @click="selectedType = data">
                      <th scope="row">
                      </th>
                      <td>
                        {{ data.name }}
                      </td>
                      <td class="action">
                        <div class="text-success" v-if="data.action === 'allow'">
                          <i class="bx bx-check-circle fs-4 align-middle"></i>
                        </div>
                        <div class="text-info" v-if="data.action === 'cdr'">
                          <i class="bx bx-check-shield fs-4 align-middle"></i>
                        </div>
                        <div class="text-danger" v-if="data.action === 'block'">
                          <i class="bx bx-block fs-4 align-middle"></i>
                        </div>
                      </td>
                      <!--                      <td class="exceptions">0</td>-->
                    </tr>
                    </tbody>
                  </table>
                  <div class="d-flex justify-content-end p-3" v-if="!this.searchQuery">
                    <div class="pagination-wrap hstack gap-2">
                      <a class="page-item pagination-prev disabled" href="#" v-if="page != 1" @click="page--">
                        Previous
                      </a>
                      <ul class="pagination listjs-pagination mb-0">
                        <li :class="{
                              active: pageNumber == page,
                              disabled: pageNumber == '...',
                            }" v-for="(pageNumber, index) in pages.slice(
                              page - 1,
                              page + 5
                            )" :key="index" @click="page = pageNumber">
                          <a class="page" href="#">{{ pageNumber }}</a>
                        </li>
                      </ul>
                      <a class="page-item pagination-next" href="#" @click="page++" v-if="page < pages.length">
                        Next
                      </a>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div class="col-xxl-6 col-lg-6">
            <div class="card" v-if="selectedType != null">
              <div class="card-header align-items-center d-flex">
                <h4 class="card-title mb-0 flex-grow-1">{{ selectedType.name }} - {{ $t("t-config-list-default") }}</h4>
              </div>
              <!-- end card-header -->
              <div class="card-body">
                <div class="row">
                  <div class="col-4 text-center" v-if="selectedType.options.includes('block')">
                    <div class="avatar-xl fs-13 mx-auto cursor-pointer" @click="selectedType.action = 'block'; updateAction(selectedType.extension, selectedType.action)">
                      <div class="p-2 text-danger rounded" :class="selectedType.action === 'block' ? 'bg-soft-danger' : ''">
                        <i class="bx bx-block fs-1 align-middle"></i><br>
                        {{ $t("t-config-action-block") }}
                      </div>
                    </div>
                  </div>
                  <div class="col-4 text-center" v-if="selectedType.options.includes('cdr')">
                    <div class="avatar-xl fs-13 mx-auto cursor-pointer" @click="selectedType.action = 'cdr'; updateAction(selectedType.extension, selectedType.action)">
                      <div class="p-2 text-info rounded" :class="selectedType.action === 'cdr' ? 'bg-soft-info' : ''">
                        <i class="bx bx-check-shield fs-1 align-middle"></i><br>
                        {{ $t("t-config-action-sanitize") }}
                      </div>
                    </div>
                  </div>
                  <div class="col-4 text-center" v-if="selectedType.options.includes('allow')">
                    <div class="avatar-xl fs-13 mx-auto cursor-pointer" @click="selectedType.action = 'allow'; updateAction(selectedType.extension, selectedType.action)">
                      <div class="p-2 text-success rounded" :class="selectedType.action === 'allow' ? 'bg-soft-success' : ''">
                        <i class="bx bx-check-circle fs-1 align-middle"></i><br>
                        {{ $t("t-config-action-allow") }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <!-- end col -->
        </div>


      </div>
      <div class="tab-pane tab-pane-config" id="nav-phishing" role="tabpanel">
        <div class="row">
          <div class="col-xxl-6 col-lg-6">
            <div class="card">
              <div class="card-header align-items-center d-flex">
                <h4 class="card-title mb-0">
                  <!--                  <span class="badge bg-info text-dark text-decoration-none" style="background-color: rgba(58, 76, 98, 0.1) !important;">ClickSafe</span>-->
                  {{ $t("t-config-menu-phishing") }}
                </h4>
                <div class="form-check form-switch form-switch-md mt-0 mb-0 pt-0 pb-0 ms-3">
                  <input class="form-check-input" type="checkbox" role="switch" @change="handlePhishingChange" :checked="servicePhishingEnabled">
                  <label>{{ $t("t-config-phishing-enable") }}</label>
                </div>
              </div>
              <!-- end card-header -->

              <div class="card-body">
                <p v-html="$t('t-config-phishing-explainer')"></p>

                <br>
                <span class="mb-1 d-block fw-semibold">
                  {{ $t("t-config-phishing-handle-title") }}
                </span>
                <div class="form-check mb-2">
                  <input class="form-check-input" v-model="clickSafeLinksOption" type="radio" name="clicksafe-links-option" id="clicksafe-links-option-1" value="block" @change="updateOption('m365_clicksafe_link_handling', clickSafeLinksOption)" :checked="options && 'm365_clicksafe_link_handling' in options && options['m365_clicksafe_link_handling'] === 'block'">
                  <label class="form-check-label" for="clicksafe-links-option-1">
                    {{ $t("t-config-phishing-handle-block") }}
                  </label>
                </div>
                <div class="form-check mb-2">
                  <input class="form-check-input" v-model="clickSafeLinksOption" type="radio" name="clicksafe-links-option" id="clicksafe-links-option-2" value="safe_mode" @change="updateOption('m365_clicksafe_link_handling', clickSafeLinksOption)" :checked="options && 'm365_clicksafe_link_handling' in options && options['m365_clicksafe_link_handling'] === 'safe_mode'">
                  <label class="form-check-label" for="clicksafe-links-option-2">
                    {{ $t("t-config-phishing-handle-safe-mode") }}
                  </label>
                </div>
                <div class="form-check mb-2">
                  <input class="form-check-input" v-model="clickSafeLinksOption" type="radio" name="clicksafe-links-option" id="clicksafe-links-option-3" value="safe_mode_navigate" @change="updateOption('m365_clicksafe_link_handling', clickSafeLinksOption)" :checked="options && 'm365_clicksafe_link_handling' in options && options['m365_clicksafe_link_handling'] === 'safe_mode_navigate'">
                  <label class="form-check-label" for="clicksafe-links-option-3">
                    {{ $t("t-config-phishing-handle-safe-mode-navigate") }}
                  </label>
                </div>

                <br>
                <span class="mb-1 d-block fw-semibold">
                  {{ $t("t-config-safety-handle-title") }}
                </span>
                <div class="form-check mb-2">
                  <input class="form-check-input" v-model="clickSafeLinksOptionNewSender" type="radio" name="clicksafe-links-new-sender-option" id="clicksafe-links-new-sender-option-1" value="redirect" @change="updateOption('m365_clicksafe_link_new_sender_handling', clickSafeLinksOptionNewSender)" :checked="options && 'm365_clicksafe_link_new_sender_handling' in options && options['m365_clicksafe_link_new_sender_handling'] === 'redirect'">
                  <label class="form-check-label" for="clicksafe-links-new-sender-option-1">
                    {{ $t("t-config-safety-handle-redirect") }}
                  </label>
                </div>
                <div class="form-check mb-2">
                  <input class="form-check-input" v-model="clickSafeLinksOptionNewSender" type="radio" name="clicksafe-links-new-sender-option" id="clicksafe-links-new-sender-option-2" value="safe_mode" @change="updateOption('m365_clicksafe_link_new_sender_handling', clickSafeLinksOptionNewSender)" :checked="options && 'm365_clicksafe_link_new_sender_handling' in options && options['m365_clicksafe_link_new_sender_handling'] === 'safe_mode'">
                  <label class="form-check-label" for="clicksafe-links-new-sender-option-2">
                    {{ $t("t-config-safety-handle-safe-mode") }}
                  </label>
                </div>
                <div class="form-check mb-2">
                  <input class="form-check-input" v-model="clickSafeLinksOptionNewSender" type="radio" name="clicksafe-links-new-sender-option" id="clicksafe-links-new-sender-option-3" value="safe_mode_sender" @change="updateOption('m365_clicksafe_link_new_sender_handling', clickSafeLinksOptionNewSender)" :checked="options && 'm365_clicksafe_link_new_sender_handling' in options && options['m365_clicksafe_link_new_sender_handling'] === 'safe_mode_sender'">
                  <label class="form-check-label" for="clicksafe-links-new-sender-option-3">
                    {{ $t("t-config-safety-handle-safe-mode-sender") }}
                  </label>
                </div>
                <div class="form-check mb-2">
                  <input class="form-check-input" v-model="clickSafeLinksOptionNewSender" type="radio" name="clicksafe-links-new-sender-option" id="clicksafe-links-new-sender-option-4" value="safe_mode_navigate" @change="updateOption('m365_clicksafe_link_new_sender_handling', clickSafeLinksOptionNewSender)" :checked="options && 'm365_clicksafe_link_new_sender_handling' in options && options['m365_clicksafe_link_new_sender_handling'] === 'safe_mode_navigate'">
                  <label class="form-check-label" for="clicksafe-links-new-sender-option-4">
                    {{ $t("t-config-safety-handle-safe-mode-navigate") }}
                  </label>
                </div>

              </div>
            </div>


          </div>
          <!-- end col -->
        </div>
      </div>

      <div class="tab-pane tab-pane-config active" id="nav-spearphishing" role="tabpanel">
        <div class="row">
          <div class="col-xxl-6 col-lg-6">
            <div class="card">
              <div class="card-header align-items-center d-flex">
                <h4 class="card-title mb-0">
                  <!--                  <span class="badge bg-info text-dark text-decoration-none" style="background-color: rgba(58, 76, 98, 0.1) !important;">ScamAlert</span>-->
                  {{ $t("t-config-menu-spearphishing") }}
                </h4>
                <div class="form-check form-switch form-switch-md mt-0 mb-0 pt-0 pb-0 ms-3">
                  <input class="form-check-input" type="checkbox" role="switch" @change="handleSpearPhishingChange" :checked="serviceSpearPhishingEnabled">
                  <label>{{ $t("t-config-spearphishing-enable") }}</label>
                </div>
              </div>
              <!-- end card-header -->
              <div class="card-body">
                <p v-html="$t('t-config-spearphishing-explainer')"></p>
                <br>

                <div style="width: 500px;">
                    <span class="mb-1 d-block fw-semibold">
                      {{ $t("t-config-spearphishing-banner-unsafe") }}
                    </span>
                  <div class="input-group">
                    <input type="text" ref="scamalert_banner_unsafe" autocomplete="off" class="form-control" :value="options && 'm365_scamalert_banner_unsafe' in options ? options['m365_scamalert_banner_unsafe'] : ''" :placeholder="'m365_scamalert_banner_unsafe' in defaults ? defaults['m365_scamalert_banner_unsafe'] : ''">
                    <span class="input-group-text cursor-pointer" @click="updateOption('m365_scamalert_banner_unsafe', $refs.scamalert_banner_unsafe.value)">{{ $t("t-config-options-m365-banner-save") }}</span>
                  </div>
                </div>

                <br />
              </div>
            </div>
          </div>
          <!-- end col -->
        </div>
      </div>
      <div class="tab-pane tab-pane-config" id="nav-other" role="tabpanel">
        <div class="row">
          <div class="col-xxl-6 col-lg-6">
            <div class="card">
              <div class="card-header align-items-center d-flex">
                <h4 class="card-title mb-0 flex-grow-1">{{ $t("t-config-menu-options") }}</h4>
              </div>
              <!-- end card-header -->
              <div class="card-body" v-if="options != null">

                <p>
                  {{ $t("t-config-options-explainer") }}
                </p>
                <br>

                <div class="form-check form-switch form-switch-md mb-2">
                  <input class="form-check-input" type="checkbox" role="switch" id="banner-disabled"
                         :checked="('m365_banner_disabled' in options && options['m365_banner_disabled'])"
                         @change="updateOption('m365_banner_disabled', $event.target.checked)">
                  <label class="form-check-label fw-semibold" for="banner-disabled">
                    {{ $t("t-config-options-m365-banner-disable") }}
                  </label>
                </div>

                <div class="form-check form-switch form-switch-md mb-3">
                  <input class="form-check-input" type="checkbox" role="switch" id="banner-bottom"
                         :checked="('m365_banner_bottom' in options && options['m365_banner_bottom'])"
                         @change="updateOption('m365_banner_bottom', $event.target.checked)">
                  <label class="form-check-label fw-semibold" for="banner-bottom">
                    {{ $t("t-config-options-m365-banner-bottom") }}
                  </label>
                </div>

                <div style="width: 500px;">
                  <span class="mb-1 d-block fw-semibold">
                    {{ $t("t-config-options-m365-banner") }}
                  </span>
                  <div class="input-group">
                    <input type="text" ref="banner" autocomplete="off" class="form-control" :value="options && 'm365_banner' in options ? options['m365_banner'] : ''" :placeholder="'m365_banner' in defaults ? defaults['m365_banner'] : ''">
                    <span class="input-group-text cursor-pointer" @click="updateOption('m365_banner', $refs.banner.value)">{{ $t("t-config-options-m365-banner-save") }}</span>
                  </div>
                </div>
                <br>
              </div>
            </div>
          </div>
          <!-- end col -->
        </div>

      </div>
    </div>




  </Layout>
</template>